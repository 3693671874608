.table {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.table-header {
    display: flex;
    background-color: #f2f2f2;
    font-weight: bold;
}

.table-row {
    display: flex;
    border-bottom: 1px solid #ddd;
}

.col {
    flex: 1;
    padding: 8px;
    text-align: left;
}

.table-row:hover {
    background-color: #f9f9f9;
}
